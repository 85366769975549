<template lang="html">
  <div class="card card-primary card-outline">
    <form @submit.prevent>
      <div class="card-header p-1">
        <h3 class="card-title">组编辑</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="title">组名称</label>
          <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入组名称">
          <div class="invalid-feedback">
            <span v-if="!$v.form.title.required">请输入组名称</span>
            <span v-if="!$v.form.title.minLength">组名称至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
            <span v-if="!$v.form.title.maxLength">组名称最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
          </div>
        </div>
        <CollapedBox collapseIcon expandCard>
          <template v-slot:header>
            基本设置
          </template>
          <div class="form-group">
            <label for="Description">简介</label>
            <textarea class="form-control" id="Description" v-model.trim="form.description" placeholder="请输入简介"></textarea>
          </div>
          <!--<div class="form-group">
            <label for="Level">级别</label>
            <select class="form-control" id="Level" v-model:value="form.level">
              <option v-for="i in levels" :value="i.value">{{i.key}}</option>
            </select>
          </div>
          <div v-if="$inRole('管理员')" class="form-group">
            <label for="ExpiryTime">有效期</label>
            <input type="date" class="form-control" id="ExpiryTime" v-model.trim="form.expiryTime" placeholder="请输入有效期">
          </div>-->
        </CollapedBox>

        <CollapedBox collapseIcon expandCard>
          <template v-slot:header>
            联系信息
          </template>
          <div>
            <div class="form-group">
              <label for="fullName">联系人</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.address.fullName.$error }" id="fullName" v-model.trim="$v.form.address.fullName.$model" placeholder="请输入联系人姓名" maxlength="10">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.fullName.required">请输入联系人姓名</span>
                <span v-else="!$v.form.address.fullName.minLength">联系人姓名至少 {{$v.form.address.fullName.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.fullName.maxLength">联系人姓名最多 {{$v.form.address.fullName.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
            <div class="form-group">
              <label for="mobile">手机</label>
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.form.address.mobile.$error }" id="mobile" v-model.trim="$v.form.address.mobile.$model" placeholder="请输入手机号码" maxlength="11">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.mobile.required">请输入手机号码</span>
                <span v-else="!$v.form.address.mobile.isMobile">不是有效的手机号码格式</span>
                <span v-else="!$v.form.address.mobile.minLength">手机号码至少 {{$v.form.address.mobile.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.mobile.maxLength">手机号码最多 {{$v.form.address.mobile.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
            <div class="form-group">
              <label for="tel">电话</label>
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.form.address.tel.$error }" id="tel" v-model.trim="$v.form.address.tel.$model" placeholder="请输入电话号码" maxlength="20">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.tel.required">请输入电话号码</span>
                <span v-else="!$v.form.address.tel.isTel">不是有效的电话号码格式</span>
              </div>
            </div>
            <div class="form-group">
              <AreaCode v-model="form.address.area" :level="3"></AreaCode>
            </div>
            <div class="form-group">
              <label for="street">地址</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.address.street.$error }" id="street" v-model.trim="$v.form.address.street.$model" placeholder="请输入地址" maxlength="200">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.street.required">请输入地址</span>
                <span v-else="!$v.form.address.street.minLength">地址至少 {{$v.form.address.street.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.street.maxLength">地址最多 {{$v.form.address.street.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
          </div>
        </CollapedBox>
        <template v-if="form.id">
          <CollapedBox ref="SetGroupAdmin" maximizeIcon>
            <template v-slot:header>
              设置管理员
            </template>
            <h4 v-if="qrcodeLoading" class="text-center">
              <i class="fas fa-sync fa-spin"></i>
            </h4>
            <img v-else :src="qrcode" class="img-fluid" />
            <!--<div class="input-group input-group-sm m-0">
    <input type="text" class="form-control" placeholder="用户名" v-model="userName">
    <div class="input-group-append">
      <button type="button" class="btn btn-primary" v-on:click="SetMaster()">
        修改
      </button>
    </div>
  </div>-->
            <div v-if="form.user">
              <Avatar size="25" :user="form.user" :cssStyle="{}" /> 用户名：{{form.user.userName}} 昵称：{{form.user.nickName}}
            </div>
            <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetGroupMaster')">绑定二维码</button>-->
          </CollapedBox>
          <CollapedBox ref="SetGroupMember" maximizeIcon>
            <template v-slot:header>
              设置成员
            </template>
            <h4 v-if="qrcodeLoading" class="text-center">
              <i class="fas fa-sync fa-spin"></i>
            </h4>
            <img v-else :src="qrcode" class="img-fluid" />
            <!--<div class="input-group input-group-sm m-0">
              <input type="text" class="form-control" placeholder="用户名" v-model="userName">
              <div class="input-group-append">
                <button type="button" class="btn btn-primary" v-on:click="SetEmployee()">
                  添加
                </button>
              </div>
            </div>-->
            <div>
              <ul v-for="e in form.employees">
                <li>
                 <Avatar size="25" :user="e" :cssStyle="{}"/> 用户名：{{e.userName}} 昵称：{{e.nickName}}
                  <button type="button" class="btn btn-danger btn-sm" v-on:click="RemoverEmployee(e.userName)">
                    移除
                  </button>
                </li>
              </ul>
            </div>
            <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetGroupEmployee')">绑定二维码</button>-->
          </CollapedBox>
        </template>
        <!--<div class="checkbox">
          <label>
            <input type="checkbox" v-model.trim="form.closed" placeholder="关闭用户组">关闭用户组
          </label>
        </div>-->

      </div>

    </form>
    <Footer>
      <button type="button" class="btn btn-default mr-2" @click.prevent="cancel">取消</button>
      <template v-if="form.id==null||form.id.length==0">
        <button type="button" @click.prevent="Create" class="btn btn-primary" :disabled="Updateing">添加</button>
      </template>
      <template v-else>
        <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary" :disabled="Updateing">更新</button>
      </template>
    </Footer>
    <BreakLeave v-if="cantleave"></BreakLeave>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import {
    AreaCode,
    Avatar,
    CollapedBox,
    CheckBox,
    PanoLib,
    Footer,
    MapBox,
    UploadSingleBox,
    FileBox,
    BreakLeave,
  } from 'common'
  import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(?::[0-9]{1,5})?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

  export default {
    components: {
      AreaCode,
      Avatar,
      PanoLib,
      MapBox,
      CheckBox,
      CollapedBox,
      FileBox,
      Footer,
      UploadSingleBox,
      BreakLeave,
      //Modal,
    },
    data() {
      return {
        id: this.$route.params.groupId,
        form: {
          id: '',
          title: '',
          description: '',
          address: {
            area: null,
            street: '',
            tel: '',
            mobile: '',
            fullName: ''
          },
          expiryTime: '',
          businessLicense: null,
          idCard: null,
          specialLicense: null,
          fireLicense: null,
          healthLicense: null,
          closed: false,
        },
        progress: 0,
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        //aMap: null,
        levels: [],
        qrcode: null,
        qrcodeLoading: false,
        userName: null,
        //areas: {
        //  province: null,
        //  provinces: null,
        //  city: null,
        //  citys: null,
        //  district: null,
        //  districts: null
        //},
        fields: [
          { key: 'more', label: '操作' },
          { key: 'id', label: 'Id' },
          { key: 'title', label: '名称' },
          { key: 'lnglat', label: '经纬度' },
          { key: 'phone', label: '联系方式' },
          { key: 'areaId', label: '区域' },
          { key: 'address', label: '地址' },
          { key: 'userId', label: '用户Id' },
          { key: 'createId', label: '创建Id' },
          { key: 'expiryTime', label: '到期时间' },
          { key: 'addTime', label: '注册时间', sortable: true }
        ],
        //pager: {
        //  prePageSize: [10, 20, 50, 100],
        //  pageIndex: 1,
        //  pageSize: 1,
        //  totalPages: 0,
        //  totalRecords: 0,
        //  data: []
        //},
        query: {
          prePageSize: [10, 20, 50, 100],
          totalPages: 0,
          totalRecords: 0,

          pageIndex: 1,
          pageSize: 20,

          sort: "addTime",
          order: true,
          fields: 'cardNo',
          key: null,
          roles: "",
          data: []
        },
        Updateing: false,
        cantleave: true,
      }
    },
    validations: {
      //areas: {
      //  province: {
      //    required,
      //  },
      //  city: {
      //    required,
      //  },
      //  district: {
      //    required,
      //  },
      //},
      form: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        address: {
          area: {
            required
          },
          mobile: {
            required,
            minLength: minLength(11),
            maxLength: maxLength(11),
            isMobile
          },
          tel: {
            //required,
            isTel
          },
          fullName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(10),
          },
          street: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(200),
          }
        },
      }
    },
    computed: {
    },
    methods: {
      formRemove(pname) {
        return () => {
          console.log('formRemove', pname)
          this.form[pname] = null;
        }
      },
      status(validation) {
        return {
          error: validation.$error,
          dirty: validation.$dirty
        }
      },
      ...mapActions([
        'setPageInfo',
      ]),
      initData() {
        //this.initMap(0, 0)
      },
      loadData() {
        this.$axios.get('/Api/GroupAdmin/Groups/Levels')
          .then(res => { this.levels = res.data })
          .catch(function (error) {
            console.log(error)
          })
        if (!this.id || this.id.length == 0) {
          this.initData();
          return;
        }
        this.$axios.get(`/Api/GroupAdmin/Groups/${this.id}`)
          .then((response) => {
            console.log(response)
            let d = response.data
            this.form = d;
            setTimeout(() => {
              $(this.$refs.SetGroupAdmin.$el).on('maximized.lte.cardwidget', () => {
                this.showQrcode('SetGroupAdmin')
              })
              $(this.$refs.SetGroupMember.$el).on('maximized.lte.cardwidget', () => {
                this.showQrcode('SetGroupMember')
              })
            })
          })
          .catch(function (error) {
            console.log(error)
          })


      },
      showQrcode(QRpath) {
        let _this = this
        console.log('showQrcode')
        //this.$bus.$emit('qrcodeModal-modal-open', {});
        //this.$refs.qrcodeModal.show()
        this.qrcodeLoading = true;
        //this.qrcode='http://localhost:5014/Api/Weixin/QrCode/'+ this.id
        this.$axios.get('/Api/Weixin/QRCode/' + QRpath + '/' + this.id)
          .then((response) => {
            this.qrcodeLoading = false;
            console.log(response)
            this.qrcode = window.global.API_BASE_URL + '/Api/QRCode?url=' + response.data.url
          })
          .catch((error) => {
            console.log(error)
          })

      },
      SetMaster() {
        if (!this.form.id) {
          return
        }
        this.$axios.put(`/Api/GroupAdmin/Groups/${this.form.id}/Master`, { userName: this.userName })
          .then((response) => {
            this.form.employees = response.data.employees
            console.log(response)
            this.$message({
              message: '修改成功！',
              type: 'info',
              zIndex: 9999,
              //showClose: true
            })
        })
          .catch((error) => {
            console.error(error)
          })
      },
      SetEmployee() {
        if (!this.form.id) {
          return
        }
        this.$axios.put(`/Api/GroupAdmin/Groups/${this.form.id}/Employee`, { userName: this.userName })
          .then((response) => {
            this.form.employees = response.data.employees
            console.log(response)
            this.$message({
              message: '添加成功！',
              type: 'info',
              zIndex: 9999,
              //showClose: true
            })
        })
          .catch((error) => {
            console.error(error)
          })
      },
      RemoverEmployee(userName) {
        if (!this.form.id) {
          return
        }
        if (!confirm(`确定移除 ${userName} 吗?`)) {
          return
        }
        this.$axios.delete(`/Api/GroupAdmin/Groups/${this.form.id}/Employee/${userName}`)
          .then((response) => {
            this.form.employees = response.data.employees
            console.log(response)
            this.$message({
              message: '移除成功！',
              type: 'info',
              zIndex: 9999,
              //showClose: true
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      //hideQrcode() {
      //    //this.$bus.$emit('qrcodeModal-modal-hide', {});
      //    this.$refs.qrcodeModal.hide()

      //},
      Create() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let d = this.form
        delete d.id
        this.Updateing = true;
        this.$axios.post('/Api/GroupAdmin/Groups', d)
          .then((response) => {
            console.log(response)
            this.id = response.data.id;
            this.Updateing = false;
            this.Close()
          })
          .catch((error) => {
            this.Updateing = false;
            console.log(error)
          })
      },
      Delete(id, i) {
        this.$axios.delete(`/Api/GroupAdmin/Groups/${id}`)
          .then((response) => {
            this.Close()
          })
          .catch((error) => {
            console.log(error)
          })
      },
      Update(id) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.Updateing = true;
        this.$axios.put(`/Api/GroupAdmin/Groups/${id}`, this.form)
          .then((response) => {
            this.$message({
              message: '保存成功！',
              type: 'info',
              zIndex: 9999,
              //showClose: true
            })
            this.Close()
            this.Updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.Updateing = false;
          })
      },
      cancel() {
        if (confirm("确定退出吗，未保存的内容将丢失")) {
          this.Close()
        } else {
          return
        }
      },
      Close() {
        this.cantleave = false
        setTimeout(() => {
          this.$router.push({ name: 'GroupHome' })
        }, 1)
      },
      idCardUploadFinished(file) {
        this.form.idCard = file;
        console.log('idCard', file)
      },
      businessLicenseUploadFinished(file) {
        this.form.businessLicense = file;
        console.log('businessLicense', file)
      },
      specialLicenseUploadFinished(file) {
        this.form.specialLicense = file;
        console.log('specialLicense', file)
      },

      fireLicenseUploadFinished(file) {
        this.form.fireLicense = file;
        console.log('fireLicense', file)
      },

      healthLicenseUploadFinished(file) {
        this.form.healthLicense = file;
        console.log('healthLicense', file)
      },

    },
    created() {
      this.loadData()
    },
    mounted() {
    },
    watch: {
    },
  }
</script>
<style lang="css">
</style>
